import styles from "./styles/SchoolsDropdown.module.scss";
import FontAwesome from "../utilities/FontAwesome";
import {useContext, useEffect, useState} from "react";
import ClickableLink from "../utilities/ClickableLink";
import classNames from 'classnames';
import {CSSTransition} from "react-transition-group";
import {Arrow} from "../utilities/Popup";
import useClickOutside from "../../hooks/useClickOutside";
import {interfaceStore, StoreContext} from "../../stores/StoreLoader";

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

export function SchoolsDropdown(props: {
    heroBlock: IHeroTerraceBlock
}) {
    const {organizationStore} = useContext(StoreContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const ref = useClickOutside(() => setDropdownOpen(false)) as React.MutableRefObject<HTMLDivElement | null>;

    const arrowClassName = classNames({
        [styles.arrow]: true,
        [styles.arrowVisible]: dropdownOpen,
    })

    const containerClassName = classNames({
        [styles.container]: true,
        // should be hidden on sub-pages and when there's no content
        [styles.containerHidden]: props.heroBlock.school_links.length === 0 || organizationStore.currentOrganization.id !== organizationStore.organization.id,
    })

    const buttonClassName = classNames({
        [styles.stickyButton]: dropdownOpen,
    })

    useEffect(() => {
        function handleKeyPress(e) {
            if (dropdownOpen && ((e.shiftKey && e.keyCode === 9) || e.key === "Escape")) {
                setDropdownOpen(false);
                setTimeout(() => (document.querySelector("#sb-open-schools-dropdown") as HTMLButtonElement).focus(), 50)
            }
        }

        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        }
    }, [dropdownOpen]);

    useEffect(() => {
        if (dropdownOpen) {
            const firstLink: HTMLLIElement | null = document.querySelector("#sb-schools-dropdown > div li a");
            if (firstLink) {
                firstLink.focus();
            }
        }
    }, [dropdownOpen])
    const groupedLinks = props.heroBlock.school_links?.reduce((grouped, link) => {
        (grouped[link.category] = grouped[link.category] || []).push(link);
        return grouped;
    }, {});

    const content = (
        <CSSTransition classNames={transitionClassNames} in={dropdownOpen} timeout={250} mountOnEnter={true}>
            <div className={styles.dropdown}>
                {interfaceStore.breakpoint === 'break-point-xs' && <div className={styles.breakContainer}>
                    <hr/>
                </div>}
                <ul id={"sb-schools-dropdown"}>
                    {groupedLinks["District"] &&
                        <div key={"District"} className={styles.categoryList}>
                            <h2>{"District"}</h2>
                            {groupedLinks["District"].map(link => (
                                <li key={link.title}>
                                    <ClickableLink href={link.url}>{link.title}</ClickableLink>
                                </li>
                            ))}
                        </div>
                    }
                    {Object.entries(groupedLinks || {}).map(([category, links]: [string, SchoolLink[]]) => (
                        category !== "District" && <div key={category} className={styles.categoryList}>
                            <h2>{category}</h2>
                            {links.map(link => (
                                <li key={link.title}>
                                    <ClickableLink href={link.url}>{link.title}</ClickableLink>
                                </li>
                            ))}
                        </div>
                    ))}
                </ul>
            </div>
        </CSSTransition>
    );

    return (
        <div className={containerClassName} ref={ref}>
            <div className={styles.desktopDropdown}>
                {content}
                <button onClick={() => setDropdownOpen(v => !v)} id={"sb-open-schools-dropdown"}
                        aria-expanded={dropdownOpen} aria-haspopup={props.heroBlock.school_links.length > 0}>
                    <span>OUR SCHOOLS&nbsp;</span><FontAwesome prefix={"fas"} name={"fa-arrow-up"}/>
                </button>
                <Arrow position={"bottom"} className={arrowClassName}/>
            </div>
            <div className={styles.mobileDropdown}>
                <button className={buttonClassName} onClick={() => {
                    setDropdownOpen(v => {
                        if (ref.current) {
                            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                    return !v;
                    })
                }} id={"sb-open-schools-dropdown"} aria-expanded={dropdownOpen}
                        aria-haspopup={props.heroBlock.school_links.length > 0}>

                    <div className={styles.buttonTextContainer}>
                        <span>OUR SCHOOLS&nbsp;</span>
                        <FontAwesome
                            className={styles.faIcon}
                            prefix={"fas"}
                            name={dropdownOpen ? "fa-angle-down" : "fa-angle-right"}/>
                    </div>


                </button>

                {content}
            </div>
        </div>
    );
}
