import { useRef, useEffect, useCallback } from 'react';

export default function useSwipe({
                                     handleRightSwipe = () => {},
                                     handleLeftSwipe = () => {},
                                     handleUpwardsSwipe = () => {},
                                     handleDownwardsSwipe = () => {},
                                 }) {
    const ref = useRef<HTMLDivElement | null>(null);

    // Stick this in a ref so it doesn't update on rerender
    const swipePosition = useRef({
        swipeStartX: 0,
        swipeStartY: 0,
        isMouseDown: false,
    });

    const handleTouchStart = useCallback(e => {
        swipePosition.current.swipeStartX = e.targetTouches[0].clientX;
        swipePosition.current.swipeStartY = e.targetTouches[0].clientY;
    }, []);

    const handleTouchMove = useCallback(e => {
        if (!swipePosition.current.swipeStartX || !swipePosition.current.swipeStartY) {
            return;
        }

        const xUp = e.touches[0].clientX;
        const yUp = e.touches[0].clientY;

        const xDiff = swipePosition.current.swipeStartX - xUp;
        const yDiff = swipePosition.current.swipeStartY - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /* most significant */
            if (xDiff > 0) {
                handleLeftSwipe();
            } else {
                handleRightSwipe();
            }
        } else if (yDiff > 0) {
            handleUpwardsSwipe();
        } else {
            handleDownwardsSwipe();
        }

        swipePosition.current.swipeStartX = 0;
        swipePosition.current.swipeStartY = 0;
    }, [handleLeftSwipe, handleRightSwipe, handleUpwardsSwipe, handleDownwardsSwipe]);

    const handleMouseDown = useCallback(e => {
        swipePosition.current.swipeStartX = e.clientX;
        swipePosition.current.swipeStartY = e.clientY;
        swipePosition.current.isMouseDown = true;
    }, []);

    const handleMouseMove = useCallback(e => {
        if (!swipePosition.current.isMouseDown) {
            return;
        }

        const xUp = e.clientX;
        const yUp = e.clientY;

        const xDiff = swipePosition.current.swipeStartX - xUp;
        const yDiff = swipePosition.current.swipeStartY - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                handleLeftSwipe();
            } else {
                handleRightSwipe();
            }
        } else if (yDiff > 0) {
            handleUpwardsSwipe();
        } else {
            handleDownwardsSwipe();
        }

        swipePosition.current.swipeStartX = 0;
        swipePosition.current.swipeStartY = 0;
        swipePosition.current.isMouseDown = false;
    }, [handleLeftSwipe, handleRightSwipe, handleUpwardsSwipe, handleDownwardsSwipe]);

    const handleMouseUp = useCallback(() => {
        swipePosition.current.isMouseDown = false;
    }, []);

    useEffect(() => {
        if (ref.current) {
            // Touch events
            ref.current.addEventListener('touchstart', handleTouchStart);
            ref.current.addEventListener('touchmove', handleTouchMove);

            // Mouse events
            ref.current.addEventListener('mousedown', handleMouseDown);
            ref.current.addEventListener('mousemove', handleMouseMove);
            ref.current.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('touchstart', handleTouchStart);
                ref.current.removeEventListener('touchmove', handleTouchMove);
                ref.current.removeEventListener('mousedown', handleMouseDown);
                ref.current.removeEventListener('mousemove', handleMouseMove);
                ref.current.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [handleTouchStart, handleTouchMove, handleMouseDown, handleMouseMove, handleMouseUp]);

    return ref;
}
