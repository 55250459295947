import {getSeededRandom} from "../utils/DataUtilities";
import {useContext, useMemo} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {useHeroTerrace} from "./useHeroTerrace";
import {useGridType} from "./useGridType";
import {GridTypes} from "../pages/MainGridPage";


export function useHeroMediaItems(isWelcomePage): IMediaItem[] {
    const {organizationStore} = useContext(StoreContext);

    const heroTerrace = useHeroTerrace();
    const gridType = useGridType();

    return useMemo(() => {
        let customMedia: IMediaItem[] = [], mediaRegistry: IMediaItem[] = [];
        if (isWelcomePage) {
            customMedia = organizationStore.organization.json_data?.settings?.hero?.filter(media => media.enabled);
            mediaRegistry = organizationStore.organization.media;
        } else if (gridType === GridTypes.terraced) {
            if (heroTerrace) {
                customMedia = heroTerrace.media.filter(media => media.enabled);
                mediaRegistry = heroTerrace.media_registry;
            }
        }
        if (customMedia.length > 0) {
            // If there are custom media, and they are all images, return them all, and we will loop through them.
            // Otherwise, pick one randomly.
            const isOnlyImages = customMedia.every(m => m.type === "image");
            if (isOnlyImages) {
                return customMedia;
            } else {
                return [getSeededRandom(customMedia)];
            }
        } else {
            return [getSeededRandom(mediaRegistry)];
        }
    }, isWelcomePage ? [organizationStore.organization.json_data?.settings?.hero, organizationStore.organization.media] :
        gridType === GridTypes.terraced ? [heroTerrace.media, heroTerrace.media_registry] :
            []);
}
