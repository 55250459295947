import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles/video.module.scss';
import { undefOrNull } from '../../../utils/booleanUtils';

export default class Video extends Component {
    static propTypes = {
        mediaUrl: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        isBackground: PropTypes.bool,
        backgroundType: PropTypes.oneOf(['cover', 'contain']).isRequired,
        height: PropTypes.string,
        width: PropTypes.string,
        description: PropTypes.string,
        autoPlay: PropTypes.bool,
        muted: PropTypes.bool,
        loop: PropTypes.bool,
        containerClassName: PropTypes.string,
        isHero: PropTypes.bool,
    };

    static defaultProps = {
        height: '100%',
        width: '100%',
        isBackground: false,
        isHero: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            autoPlay: props.autoPlay,
        }
    }

    componentDidMount() {
        // https://thenewcode.com/777/Create-Fullscreen-HTML5-Page-Background-Video
        // this is an accessibility thing.  Always override our preferences if a person wants reduced motion.
        if (window.matchMedia('(prefers-reduced-motion)').matches) {
            this.setState({
                autoPlay: false,
            });
        }
    }

    render() {
        const { mediaUrl, imageUrl, backgroundType, height, width, description, isBackground, containerClassName: videoContainerClassName, ...restProps } = this.props;

        const containerClassName = classNames({
            [styles.container]: true,
            [videoContainerClassName]: videoContainerClassName,
            [styles.isHero]: this.props.isHero,
        });

        const videoClassName = classNames({
            [styles.cover]: backgroundType === 'cover',
            [styles.contain]: backgroundType === 'contain',
        });

        let doLoop = this.props.loop,
            doMuted = this.props.muted,
            doAutoPlay = this.state.autoPlay;

        if (this.props.isBackground === true) {
            doLoop = undefOrNull(this.props.loop) ? true : this.props.loop;
            doMuted = undefOrNull(this.props.muted) ? true : this.props.muted;
            doAutoPlay = undefOrNull(this.state.autoPlay) ? true : this.state.autoPlay;
        }

        return (
            <div role="presentation" className={containerClassName} style={{height, width}} aria-label={description}>
                <video key={mediaUrl} preload={"auto"} loop={doLoop} muted={doMuted} autoPlay={doAutoPlay} playsInline={doAutoPlay}
                    poster={imageUrl} className={videoClassName} {...restProps}>
                    <source src={mediaUrl} />
                </video>
                { this.props.children }
            </div>
        );

    }
}
