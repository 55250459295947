import {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import styles from "./styles/HeroContainer.module.scss";
import Button from "../utilities/Button";
import FlatIcon from "../utilities/FlatIcon";
import {StoreContext} from "../../stores/StoreLoader";
import {SchoolsDropdown} from "./SchoolsDropdown";
import {useHeroTerrace} from "../../hooks/useHeroTerrace";
import {HeroMediaElement} from "./HeroMediaElement";
import LogoAndTitle from "./LogoAndTitle";


const HeroContainer = observer((props: {
    displayViewHomepageButton: boolean,
    handleViewHomepage: () => void,
    isWelcomePage: boolean,
    secondaryNavHeight: number,
}) => {
    const {organizationStore, userStore, modalStore, styleStore} = useContext(StoreContext);

    const heroBlock = useHeroTerrace();
    const showSchoolsDropdown = heroBlock && !props.isWelcomePage;
    const editHeroButtonClassName = classNames({
        [styles.editHeroButton]: true,
        [styles.editHeroButtonOnWelcomePage]: props.isWelcomePage,
    });

    function openEditHeroModal() {
        modalStore.addModal({
            type: "edit-hero",
            id: "edit-hero",
        })
    }

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerTitleOrg]: organizationStore.currentOrganization.id === organizationStore.organization.id,
        [styles.containerIsWelcomePage]: props.isWelcomePage,
        [styles.containerFloatingBlocksTheme]: styleStore.themeID === "2",
    });
    const isInternalPage = organizationStore.currentOrganization.id !== organizationStore.organization.id;
    const dividerClassName = classNames({
        [styles.divider]: isInternalPage,
    });

    return (
        <div>
            <div className={containerClassName} id={"hero"} style={{height: props.isWelcomePage?  '100vh' : undefined}}>
                <HeroMediaElement isWelcomePage={props.isWelcomePage}/>
                <LogoAndTitle displayViewHomepageButton={props.displayViewHomepageButton}
                              isWelcomePage={props.isWelcomePage}
                              handleViewHomepage={props.handleViewHomepage}
                              logoClassName={styles.logo}
                              titleClassName={styles.title}
                              />
                {userStore.isEditor &&
                    <Button className={editHeroButtonClassName} onClick={openEditHeroModal}
                            aria-label={"Edit Hero Image"}
                            tabIndex="3">
                        <FlatIcon name={"flaticon-edit-1"}/>
                    </Button>}
                {showSchoolsDropdown &&
                    <div className={styles.desktopDropDownContainer}>
                        <SchoolsDropdown heroBlock={heroBlock} />
                    </div>
                }
            </div>
            {showSchoolsDropdown &&
                <div className={styles.mobileDropDownContainer}>
                    <SchoolsDropdown heroBlock={heroBlock} />
                </div>
            }
            <div className={dividerClassName}/>
        </div>
    );
})

export default HeroContainer;
