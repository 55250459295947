import React from 'react';
import styles from './styles/CallToActionWidget.module.scss';
import ClickableLink from "../utilities/ClickableLink";
import classNames from "classnames";
import FontAwesome from "../utilities/FontAwesome";

type CallToActionWidgetProps = {
    cta: ICallToActionItem;
    isPreview?: boolean;
    isMobilePreview?: boolean;
    isDesktopPreview?: boolean;
    style?: object;
    isVideo?: boolean;
    isHomePage?: boolean;
};

const CallToActionWidget = (props: CallToActionWidgetProps) => {
    const { cta, isPreview, isMobilePreview, isDesktopPreview,style, isVideo, isHomePage } = props;

    const [hidden, setHidden] = React.useState(false);

    const containerClassNames = classNames({
        [styles.hidden]: hidden,
        [styles.callToActionContainer]: !hidden,
        [styles.callToActionContainerHomePage]: isHomePage,
        [styles.callToActionContainerPreview]: isPreview,
        [styles.callToActionContainerPreviewMobile]: isMobilePreview,
        [styles.callToActionContainerPreviewMobileVideo]: isMobilePreview && isVideo,
        [styles.callToActionContainerPreviewDesktop]: isDesktopPreview,
    });

    const minimizedClassNames = classNames({
        [styles.hidden]: !hidden,
        [styles.callToActionMinimized]: hidden,
    });

    const ctaBodyClassNames = classNames({
        [styles.callToActionBody]: true,
        [styles.callToActionBodyPreview]: isPreview,
        [styles.callToActionBodyPreviewDesktop]: isDesktopPreview,
        [styles.callToActionBodyPreviewMobile]: isMobilePreview,
    });

    const ctaButtonClassNames = classNames({
        [styles.callToActionLink]: true,
        [styles.callToActionLinkPreview]: isPreview,
        [styles.callToActionLinkPreviewDesktop]: isDesktopPreview,
        [styles.callToActionLinkPreviewMobile]: isMobilePreview,
    });

    const closeButtonClassNames = classNames({
        [styles.closeButton]: true,
        [styles.closeButtonPreview]: isPreview,
        [styles.closeButtonPreviewMobile]: isMobilePreview,
    });

    return (
        <>
            <div className={containerClassNames} style={style}>

                <button aria-label={'Close CTA'} className={closeButtonClassNames} onClick={() => setHidden(true)}><FontAwesome
                    name="fa-xmark"
                    prefix={'fas'}/>
                </button>

                <div className={ctaBodyClassNames}>

                    <span>{cta.title}</span>
                    <p>{cta.message}</p>
                    {cta.button_toggle && (
                        <div className={styles.buttonPreviewContainer}>
                            <ClickableLink className={ctaButtonClassNames} href={cta.button_link}>
                                <span>{cta.button_text}</span>
                            </ClickableLink>
                        </div>
                    )}
                </div>
            </div>
            <div className={minimizedClassNames} style={style}>
                <button onClick={() => setHidden(false)}>
                    <span>{cta.title}</span>
                </button>
            </div>
        </>
    );
};

export default CallToActionWidget;
