import { useHeroMediaItems } from "../../hooks/useHeroMediaItems";
import { ReactNode, useContext, useEffect, useState } from "react";
import Image from "../media/image/Image";
import Video from "../media/video/Video";
import styles from "./styles/HeroContainer.module.scss";
import FontAwesome from "../utilities/FontAwesome";
import Unslider from "../utilities/Unslider";
import { getCdnUrl } from "../../utils/SchoolBlocksUtilities";
import classNames from "classnames";
import {StoreContext} from "../../stores/StoreLoader";
import CallToActionWidget from './CallToActionWidget';
import {CSSTransition} from "react-transition-group";

interface HeroMediaElementProps {
    isWelcomePage: boolean;
}

const slideClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

export function HeroMediaElement(props: HeroMediaElementProps) {
    const { organizationStore, interfaceStore } = useContext(StoreContext);
    const [videoPaused, setVideoPaused] = useState(false);
    const heroMediaItems = useHeroMediaItems(props.isWelcomePage);
    const [currIndex, setCurrIndex] = useState(0);

    useEffect(() => {
        const video = document.getElementById("sb-hero-video") as HTMLVideoElement;

        function pausePlayVideo() {
            if (video.paused || video.ended) {
                video.play();
                setVideoPaused(false);
            } else {
                video.pause();
                setVideoPaused(true);
            }
        }

        const playPauseButton = document.getElementById("sb-hero-playpause") as HTMLButtonElement;

        if (heroMediaItems?.[0]?.type === "video") {
            playPauseButton.addEventListener("click", pausePlayVideo);
        }

        return () => {
            if (heroMediaItems?.[0]?.type === "video") {
                playPauseButton.removeEventListener("click", pausePlayVideo);
            }
        };
    }, [heroMediaItems]);
    let bgMedia: string | ReactNode = "";
    if (heroMediaItems.length > 1) {
        bgMedia = (
            <Unslider slideContainerStyles={{
                width: `calc(100vw * ${heroMediaItems.length})`,
                height: props.isWelcomePage ? "calc(100vh - 50px)" : heroMediaItems[currIndex]?.call_to_action || props.isWelcomePage ? undefined : "75vw",
                transform: `translateX(calc(${currIndex} * -100vw))`,
            }}
                      controlsStyles={styles.heroControls}
                      delay={5000} containerClassName={styles.heroElementUnslider} setCurrentIndex={setCurrIndex} currentIndex={currIndex}>
                {heroMediaItems.map((mediaItem, index) => {
                    const mediaUrl = mediaItem.is_custom ? getCdnUrl(mediaItem.media_url) : mediaItem.media_url;
                    return <CSSTransition in={index === currIndex} timeout={1000} classNames={slideClassNames} key={index}>
                        <div >
                            <Image
                                backgroundType="cover"
                                isBackground={true}
                                imageUrl={mediaUrl}
                                isWelcomePage={props.isWelcomePage}
                            />
                            {heroMediaItems[currIndex]?.call_to_action && mediaItem === heroMediaItems[currIndex] && mediaItem?.call_to_action && (
                                <CallToActionWidget cta={mediaItem.call_to_action} style={{transform: interfaceStore.breakpoint!=='break-point-xs' ?`translateX(calc(${currIndex} * 100vw)` : ''}} isHomePage={organizationStore.currentOrganization.id === organizationStore.organization.id}/>
                            )}
                        </div>
                    </CSSTransition>
                })}
            </Unslider>
        );
    } else if (heroMediaItems[0].type === "image") {
        const mediaUrl = heroMediaItems[0].is_custom ? getCdnUrl(heroMediaItems[0].media_url) : heroMediaItems[0].media_url;
        bgMedia = (
            <div>
                <Image backgroundType="cover" isWelcomePage={props.isWelcomePage} isBackground={true} imageUrl={mediaUrl} />
                {heroMediaItems[0].call_to_action && (
                    <CallToActionWidget cta={heroMediaItems[0].call_to_action} isHomePage={organizationStore.currentOrganization.id === organizationStore.organization.id}/>
                )}
            </div>
        );
    } else if (heroMediaItems[0].type === "video") {
        const mediaUrl = heroMediaItems[0].is_custom ? getCdnUrl(heroMediaItems[0].media_url) : heroMediaItems[0].media_url;
        bgMedia = (
            <div>
                <Video backgroundType="cover" isBackground={true} id={"sb-hero-video"} mediaUrl={mediaUrl} isHero={true}/>
                {heroMediaItems[0]?.call_to_action && (
                    <CallToActionWidget cta={heroMediaItems[0].call_to_action} isHomePage={organizationStore.currentOrganization.id === organizationStore.organization.id}/>
                )}
            </div>
        );
    }

    const heroElementContainer = classNames({
        [styles.heroElementContainer]: true,
        [styles.heroElementContainerTitleOrg]: organizationStore.currentOrganization.id === organizationStore.organization.id,
    });

    return (
        <div className={heroElementContainer}>
            {bgMedia}
            {heroMediaItems[0].type === "video" && (
                <button aria-label={videoPaused ? "Play Video" : "Pause Video"} id={"sb-hero-playpause"} className={styles.playPauseButton} tabIndex={1}>
                    <FontAwesome ariaHidden={true} prefix={"fas"} name={videoPaused ? "fa-play" : "fa-pause"} />
                </button>
            )}
        </div>
    );
}
